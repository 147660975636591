import CallPages from '@constants/CallPages'
import { providerUrl } from '@helpers/RoutingHelper'

// Public: Specifies all the menu items that can be displayed in the Manage menu.
export default [
  {
    group: 'Global Config',
    items: [
      {
        title: 'Login Page Content',
        permission: 'marketing_content',
        to: 'LoginPageContent',
      },
    ],
  },
  {
    group: 'Provider Config',
    items: [
      {
        title: 'Care Providers',
        permission: 'internal_only',
        to: 'CareProviders',
        phrases: [
          'Providers',
          'All Care Providers',
        ],
      },
      {
        title: 'Custom Playback Fields',
        permission: 'manage_provider_settings',
        to: 'CustomPlaybackFields',
      },
      {
        title: 'Patient Tags',
        permission: 'manage_patient_tags',
        to: 'PatientTags',
      },
      {
        title: 'Provider Settings',
        permission: 'manage_provider_settings',
        to: 'ProviderSettings',
        phrases: [
          'Support Email',
        ],
      },
      {
        title: 'Patient Identification',
        permission: 'manage_provider_settings',
        to: { name: 'ProviderSettings', query: { section: 'patient_identification' } },
        phrases: [
          'Patient-Matching Algorithm',
        ],
      },
      {
        title: 'Form Field Settings',
        permission: 'manage_provider_settings',
        to: { name: 'ProviderSettings', query: { sidebar: 'form_field_settings' } },
        phrases: [
          'Required Form Fields',
        ],
      },
      {
        title: 'View Configuration Settings',
        permission: 'manage_provider_settings',
        to: { name: 'ProviderSettings', query: { sidebar: 'view_configuration_settings' } },
      },
    ],
  },
  {
    group: 'Users & Permissions',
    items: [
      {
        title: 'Users Management',
        permission: 'user_invitations user_view',
        to: 'UsersManagement',
        phrases: [
          'Invite a user',
        ],
      },
      {
        title: 'Data Views',
        permission: 'view_management',
        to: 'DataViews',
        phrases: [
          'Columns displayed in a dashboard',
          'Conditional Formats in a table',
        ],
      },
      {
        title: 'Provider Roles',
        permission: 'user_provider_roles',
        to: 'Roles',
        phrases: [
          'Assign views to a user',
          'Permissions for a user',
          'User Criteria Configuration',
        ],
      },
      {
        title: 'User Behavior Events',
        permission: 'user_behavior',
        to: 'UserBehaviorEvents',
      },
      {
        title: 'Legacy Manage Users',
        permission: 'limited_user_configuration',
        to: providerUrl('/user_configuration'),
      },
    ],
  },
  {
    group: 'Calls',
    items: CallPages,
  },
  {
    group: 'Issues & Alerts',
    items: [
      {
        title: 'Email Events',
        permission: 'email_events',
        to: 'EmailEvents',
      },
      {
        title: 'Issue Alerts',
        shortTitle: 'Alerts',
        permission: 'alert_view',
        to: 'IssueAlerts',
        phrases: [
          'Send test alerts',
        ],
      },
      {
        title: 'Issue Panels',
        permission: 'issue_panel_view',
        to: 'IssuePanels',
        phrases: [
          'Design issue resolution panel',
        ],
      },
    ],
  },
  {
    group: 'Outreach',
    items: [
      {
        title: 'Audio Library',
        permission: 'audio_library',
        to: 'AudioLibrary',
        phrases: [
          'Audio Clips for Questions',
        ],
      },
      {
        title: 'Blacklisted Phone Numbers',
        permission: 'manage_do_not_call_list',
        to: 'BlacklistedPhones',
        phrases: [
          'Do-Not-Call list of phone numbers',
        ],
      },
      {
        title: 'Outreach And Care Recordings Configuration',
        permission: 'manage_provider_settings',
        to: { name: 'OutreachAndCareRecordingsConfiguration', query: { sidebar: 'care_recordings_integrations' } },
      },
      {
        title: 'Live Transfer',
        permission: 'live_transfer',
        to: 'LiveTransfer',
        phrases: [
          'Phone numbers for live transfers',
        ],
      },
      {
        title: 'Outreach Programs',
        permission: ['outreach_program_view'],
        to: 'OutreachPrograms',
      },
      {
        title: 'Outreach Actions',
        permission: ['outreach_program_view'],
        to: 'OutreachActions',
      },
      {
        title: 'Question Library',
        permission: 'question_library',
        to: 'QuestionLibrary',
        phrases: [
          'Question Templates for Outreach Programs',
        ],
      },
      {
        title: 'Outreach Blockages',
        permission: 'blocked_patients',
        to: 'OutreachBlockages',
      },
      {
        title: 'Outreach Settings',
        permission: 'manage_provider_settings',
        to: 'OutreachAndCareRecordingsConfiguration',
      },
    ],
  },
  {
    group: 'Import Data',
    items: [
      {
        title: 'Import Call Centers',
        permission: 'import_call_centers',
        to: { name: 'NewImport', params: { collection: 'call_centers' } },
      },
      {
        title: 'Import Community Resources',
        permission: 'community_resources_management',
        to: { name: 'NewImport', params: { collection: 'community_resources' } },
      },
      {
        title: 'Import Departments',
        permission: 'import_departments',
        to: { name: 'NewImport', params: { collection: 'departments' } },
      },
      {
        title: 'Import Kiosk Devices',
        permission: 'devices_import',
        to: { name: 'NewImport', params: { collection: 'survey_devices' } },
      },
      {
        title: 'Import Kiosk Locations',
        permission: 'survey_locations_import',
        to: { name: 'NewImport', params: { collection: 'survey_locations' } },
      },
      {
        title: 'Import Locations',
        permission: 'location_create',
        to: { name: 'NewImport', params: { collection: 'locations' } },
      },
      {
        title: 'Import Patients',
        permission: 'patient_import',
        to: { name: 'NewImport', params: { collection: 'patients' } },
      },
      {
        title: 'Import Staff Members',
        permission: 'staff_create',
        to: { name: 'NewImport', params: { collection: 'staff_members' } },
      },
      {
        title: 'Import Units',
        permission: 'internal_only',
        to: { name: 'NewImport', params: { collection: 'units' } },
      },
      {
        title: 'Import Users',
        permission: 'user_invitations',
        to: { name: 'NewImport', params: { collection: 'users' } },
      },
      {
        title: 'Import Visit Providers',
        permission: 'internal_only',
        to: { name: 'NewImport', params: { collection: 'visit_providers' } },
      },
      {
        title: 'Import Visit Types',
        permission: 'internal_only',
        to: { name: 'NewImport', params: { collection: 'visit_types' } },
      },
    ],
  },
  {
    group: 'File Imports',
    items: [
      {
        title: 'File Import Associations',
        shortTitle: 'File Import Associations',
        permission: 'internal_only',
        to: 'FileImportAssociations',
        phrases: [
          'Data mappings for file imports',
        ],
      },
      {
        title: 'File Imports History',
        shortTitle: 'File Imports History',
        permission: 'files_management',
        to: 'FileImportsHistory',
        phrases: [
          'Import Status or Progress',
        ],
      },
      {
        title: 'Import Data From File',
        shortTitle: 'Import data',
        permission: 'files_management',
        to: 'NewImport',
      },
      {
        title: 'Imports Configuration',
        shortTitle: 'Imports Configuration',
        permission: 'sftp_management',
        to: 'ImportsConfiguration',
        phrases: [
          'SFTP Settings',
        ],
      },
      {
        title: 'File Import Statistics',
        shortTitle: 'Import Statistics',
        permission: 'internal_only',
        to: providerUrl('/data_exchange/import_stats'),
      },
      {
        title: 'Custom Converters',
        permission: 'manage_custom_converters',
        to: 'CustomConverters',
      },
      {
        title: 'Readmission Imports',
        to: 'ReadmissionImports',
        permission: 'files_management',
        phrases: [
          'Upload readmissions outcomes',
        ],
      },
      {
        title: 'File Directories',
        permission: 'files_management',
        to: 'SftpFilesManagement',
        phrases: [
          'Manage SFTP Files',
        ],
      },
    ],
  },
  {
    group: 'HL7',
    items: [
      {
        title: 'HL7 Inbound History',
        to: 'Hl7InboundHistory',
        permission: 'hl7_inbound_management',
      },
      {
        title: 'HL7 Settings',
        to: { name: 'ImportsConfiguration', query: { sidebar: 'hl7_settings' } },
        permission: 'sftp_management',
      },
      {
        title: 'HL7 Inbound Mappings Configuration',
        to: 'Hl7InboundMappingAssociation',
        permission: 'hl7_inbound_management',
      },
      {
        title: 'HL7 Outbound History',
        to: 'Hl7OutboundHistory',
        permission: 'hl7_outbound_management',
      },
      {
        title: 'HL7 Outbound Configuration',
        to: 'Hl7OutboundSettings',
        permission: 'hl7_outbound_management',
      },
      {
        title: 'HL7 Outbound Message Settings',
        to: 'Hl7OutboundMessageSettings',
        permission: 'hl7_outbound_management',
      },
      {
        title: 'HL7 Custom Converters',
        to: 'Hl7CustomConverters',
        permission: 'hl7_custom_converters_view',
        if: careProvider => careProvider.hl7Ascended,
      },
    ],
  },
  {
    group: 'File Exports',
    items: [
      {
        title: 'File Export Views',
        shortTitle: 'Export Views',
        permission: 'export_views_management',
        to: 'DataViews',
      },
      {
        title: 'File Export Jobs',
        shortTitle: 'Export Jobs',
        permission: 'export_jobs_management',
        to: 'ExportJobs',
      },
      {
        title: 'File Export Job Attempts',
        shortTitle: 'Job Attempts',
        to: '/admin/export_job_attempts',
        permission: 'export_attempts',
        phrases: [
          'Check sent export jobs',
        ],
      },
      {
        title: 'File Export Manual Attempts',
        shortTitle: 'Manual Attempts',
        to: '/admin/export_manual_attempts',
        permission: 'export_attempts',
      },
      {
        title: 'File Export Attempts Admin Panel',
        shortTitle: 'Export Attempts Admin Panel',
        to: 'ExportAttemptsAdminPanel',
        permission: 'export_attempts',
      },
    ],
  },
  {
    group: 'Reporting',
    items: [
      {
        title: 'Insights Dashboards',
        to: 'InsightsDashboards',
        permission: 'insight_dashboard_view',
      },
      {
        title: 'Report Packages',
        to: 'ReportPackages',
        permission: 'report_packages_view',
        phrases: [
          'Scheduled Reports',
        ],
      },
      {
        title: 'Report Package Events',
        to: 'ReportPackageEvents',
        permission: 'report_packages_admin',
        phrases: [
          'Sent Report Packages',
        ],
      },
      {
        title: 'My Report Subscriptions',
        to: 'MyReportSubscriptions',
        permission: 'report_packages_view',
        phrases: [
          'Unsubscribe a Report Package',
          'Report Package Recipients',
          'Report Package Passwords',
        ],
      },
    ],
  },
  {
    group: 'Rules & Integrations',
    items: [
      {
        title: 'Integrations',
        to: 'Integrations',
        permission: 'integration_management',
      },
      {
        title: 'Encounter Criteria',
        permission: 'internal_only',
        to: 'EncounterCriterias',
      },
      {
        title: 'Import Rules',
        permission: 'internal_only',
        to: 'ImportRules',
      },
      {
        title: 'Interaction Enrollers',
        permission: 'integration_management',
        to: providerUrl('/integrations'),
      },
      {
        title: 'Key Metric Calculators',
        permission: 'integration_management',
        to: 'KeyMetricIntegrations',
      },
      {
        title: 'Key Metric Rules',
        permission: 'integration_management',
        to: providerUrl('/key_metric_enrollers'),
      },
      {
        title: 'Membership Rules',
        permission: 'integration_management',
        to: 'MembershipRules',
        phrases: [
          'Automatic enrollment in workflows',
        ],
      },
      {
        title: 'PAM Scoring',
        permission: 'internal_only',
        to: providerUrl('/pam_scoring_integrations'),
      },
      {
        title: 'Rules',
        permission: 'internal_only',
        to: providerUrl('/admin/rules'),
      },
      {
        title: 'Staff Member Criteria',
        permission: 'internal_only',
        to: providerUrl('/admin/staff_member_criteria'),
      },
    ],
  },
  {
    group: 'Rounding',
    items: [
      {
        title: 'Global Rounding Standardized Questions',
        shortTitle: 'Global Standardized Questions',
        permission: 'internal_only',
        to: providerUrl('/rounding_scripts/global_standardized_questions'),
      },
      {
        title: 'Rounding Flags',
        permission: 'manage_provider_settings',
        to: 'RoundingFlags',
      },
      {
        title: 'Rounding Goals',
        permission: 'manage_provider_settings',
        to: 'RoundingGoals',
      },
      {
        title: 'Rounding Scripts',
        permission: ['rounding_script_view', 'rounding_script_management'],
        to: providerUrl('/rounding_scripts'),
      },
      {
        title: 'Rounding Script Plugins',
        permission: 'internal_only',
        to: providerUrl('/rounding_scripts/plugins'),
      },
      {
        title: 'Rounding Standardized Questions',
        shortTitle: 'Standardized Questions',
        permission: ['rounding_script_view', 'rounding_script_management'],
        to: providerUrl('/rounding_scripts/standardized_questions'),
      },
      {
        title: 'ServiceNow Plugin Mapping Associations',
        permission: 'internal_only',
        to: 'ServiceNowPluginMappingAssociations',
      },
    ],
  },
  {
    group: 'Team Engagement',
    items: [
      {
        title: 'Care Gram Email Templates',
        permission: 'manage_provider_settings',
        to: providerUrl('/care_gram_email_templates'),
      },
      {
        title: 'Care Gram Emails',
        permission: 'manage_provider_settings',
        to: 'CareGramEmailsSearch',
      },
      {
        title: 'Global Care Gram Template',
        permission: 'internal_only',
        to: 'GlobalDefaultCareGramEmailTemplate',
      },
      {
        title: 'Rounding Badges',
        permission: 'manage_provider_settings',
        to: 'RoundingBadges',
      },
    ],
  },
  {
    group: 'Rounding Config',
    items: [
      {
        title: 'Floorplan Configuration',
        permission: 'manage_provider_settings',
        to: { name: 'RoundingConfiguration', query: { sidebar: 'floorplan_configuration' } },
      },
      {
        title: 'Rounding Configuration',
        permission: 'manage_provider_settings',
        to: 'RoundingConfiguration',
      },
      {
        title: 'Pathways',
        permission: 'manage_pathways',
        to: '/pathways',
        phrases: [
          'Stages and transitions when rounding',
        ],
      },
    ],
  },
  {
    group: 'CipherRounds',
    items: [
      {
        title: 'CipherRounds Mobile Version',
        permission: 'internal_only',
        to: 'MobileStoreVersion',
      },
      {
        title: 'Rounding Dashboards',
        permission: 'manage_rounding_dashboards',
        to: 'RoundingDashboards',
      },
      {
        title: 'Rounding Graphs',
        permission: 'manage_provider_settings',
        to: providerUrl('/orchid_graphs'),
      },
      {
        title: 'Rounding Staff Graphs',
        permission: 'manage_provider_settings',
        to: providerUrl('/orchid_staff_graphs'),
      },
      {
        title: 'Rounding Location Graphs',
        permission: 'manage_provider_settings',
        to: providerUrl('/orchid_location_graphs'),
      },
    ],
  },
  {
    group: 'Kiosk',
    items: [
      {
        title: 'Kiosk Devices',
        shortTitle: 'Devices',
        permission: 'kiosk_management',
        to: 'SurveyDevices',
      },
      {
        title: 'Kiosk Locations',
        shortTitle: 'Locations',
        permission: 'kiosk_management',
        to: 'SurveyLocations',
        phrases: [
          'Locations of Kiosk devices',
        ],
      },
      {
        title: 'Kiosk Promotions',
        shortTitle: 'Promotions',
        permission: 'kiosk_management',
        to: 'SurveyPromotions',
      },
      {
        title: 'Kiosk Reputation Emails',
        shortTitle: 'Reputation Emails',
        permission: 'kiosk_management',
        to: 'SurveyReputationEmails',
      },
      {
        title: 'Kiosk Settings',
        shortTitle: 'Settings',
        permission: 'kiosk_management',
        to: providerUrl('/survey_settings'),
        phrases: [
          'Kiosk Appearance',
          'Settings for Send Survey and Reputation',
        ],
      },
      {
        title: 'Kiosk Standardized Questions',
        shortTitle: 'Standardized Questions',
        permission: 'kiosk_management',
        to: providerUrl('/survey_scripts/standardized_questions'),
      },
      {
        title: 'Kiosk Surveys',
        shortTitle: 'Surveys',
        permission: 'kiosk_view',
        to: providerUrl('/survey_scripts'),
      },
      {
        title: 'Sent Kiosk Surveys',
        shortTitle: 'Sent Surveys',
        permission: 'send_kiosk_sms',
        to: 'SentSurveys',
        phrases: [
          'Send Kiosk Survey',
        ],
      },
    ],
  },
  {
    group: 'Units & Departments',
    items: [
      {
        title: 'Call Centers',
        permission: 'units_and_related_view',
        to: 'CallCenters',
      },
      {
        title: 'Departments',
        permission: 'units_and_related_view',
        to: 'Departments',
      },
      {
        title: 'Facilities',
        permission: 'units_and_related_view',
        to: 'Facilities',
      },
      {
        title: 'Location Groups',
        permission: 'units_and_related_view',
        to: 'LocationGroups',
      },
      {
        title: 'Units',
        permission: 'units_and_related_view',
        to: 'Units',
        phrases: [
          'Roundable Units',
        ],
      },
      {
        title: 'Visit Providers',
        permission: 'units_and_related_view',
        to: 'VisitProviders',
      },
      {
        title: 'Visit Type Groups',
        permission: 'visit_type_group_view',
        to: 'VisitTypeGroups',
      },
      {
        title: 'Visit Types',
        permission: 'units_and_related_view',
        to: 'VisitTypes',
      },
    ],
  },
  {
    group: 'My Rounds',
    items: [
      {
        title: 'Patient Rounds',
        permission: ['patient_web_rounding', 'mobile_rounding', 'see_user_rounds'],
        to: 'MyPatientRounds',
      },
      {
        title: 'Staff Rounds',
        permission: ['staff_web_rounding', 'mobile_rounding', 'see_user_rounds'],
        to: 'MyStaffRounds',
      },
      {
        title: 'Location Rounds',
        permission: ['location_web_rounding', 'mobile_rounding', 'see_user_rounds'],
        to: 'MyLocationRounds',
      },
    ],
  },
  {
    group: 'Internal Tools',
    items: [
      {
        title: 'Cipher Design System',
        permission: 'internal_only',
        to: 'StyleGuideOverview',
        phrases: [
          'Components Styleguide',
        ],
      },
      {
        title: 'Color Palette',
        permission: 'internal_only',
        to: 'StyleGuideColors',
      },
      {
        title: 'Icons Styleguide',
        permission: 'internal_only',
        to: 'StyleGuideIcons',
      },
      {
        title: 'Demo Data',
        permission: 'manage_provider_settings',
        to: 'DemoData',
        phrases: [
          'Generate Test or Mock Data',
          'Data Generation for test care providers',
        ],
      },
      {
        title: 'Migrations',
        permission: 'internal_only',
        to: 'Migrations',
      },
    ],
  },
  {
    group: 'Care Forms & Workflows',
    items: [
      {
        title: 'Care Form Configs',
        permission: 'care_form_builder',
        to: providerUrl('/care_form_configs'),
      },
      {
        title: 'Patient List Configs',
        permission: 'patient_list_manage',
        to: providerUrl('/patient_list_configs'),
        phrases: [
          'Case conference lists',
          'Worklists',
        ],
      },
      {
        title: 'Workflow Settings',
        permission: 'view_configuration_settings',
        to: { name: 'ProviderSettings', query: { sidebar: 'view_configuration_settings' } },
        phrases: [
          'Referral Sources',
        ],
      },
      {
        title: 'Workflow Status Change Reasons',
        permission: 'manage_workflow_status_change_reasons',
        to: 'WorkflowStatusChangeReasons',
        phrases: [
          'Reason for unenrollment or deletion',
        ],
      },
      {
        title: 'Workflow Templates',
        permission: 'manage_blueprints',
        to: 'WorkflowTemplates',
      },
    ],
  },
  {
    group: 'Pulse',
    items: [
      {
        title: 'Pulse Email Templates',
        permission: 'pulse_management',
        to: providerUrl('/employee_engagement/pulse_email_template'),
      },
      {
        title: 'Pulse Groups',
        permission: 'pulse_management',
        to: providerUrl('/employee_engagement/pulse_group'),
      },
      {
        title: 'Pulse Scripts',
        permission: 'pulse_management',
        to: providerUrl('/employee_engagement/pulse_scripts'),
      },
      {
        title: 'Pulse',
        permission: 'pulse_management',
        to: providerUrl('/employee_engagement/pulse'),
      },
      {
        title: 'Reverb',
        permission: 'reverb_management',
        to: providerUrl('/employee_engagement/reverb'),
      },
    ],
  },
  {
    group: 'Security & Auth',
    items: [
      {
        title: 'Failed Sign In Attempts',
        permission: 'internal_only',
        to: 'FailedSignInAttempts',
      },
      {
        title: 'IP Addresses Whitelist',
        permission: 'manage_provider_settings',
        to: { name: 'ProviderSettings', query: { sidebar: 'security_settings', section: 'ip_addresses_whitelist' } },
      },
      {
        title: 'Password Settings',
        permission: 'manage_provider_settings',
        to: { name: 'ProviderSettings', query: { sidebar: 'password_settings' } },
      },
      {
        title: 'SSO Connections',
        permission: 'internal_only',
        to: '/admin/sso_connections',
      },
      {
        title: 'SSO Settings',
        permission: 'manage_provider_settings',
        to: { name: 'ProviderSettings', query: { sidebar: 'security_settings' } },
        phrases: [
          'Single Sign On',
          'Federated Single Logout',
          'SSO Invite Email',
        ],
      },
      {
        title: 'System Super Admins',
        permission: 'manage_system_admins',
        to: 'SystemSuperAdmins',
      },
    ],
  }, {
    group: 'Cipher Connect',
    items: [
      {
        title: 'Cipher Connect Programs',
        permission: 'cipher_connect_program_view',
        to: 'CipherConnectPrograms',
      },
    ],
  }, {
    group: 'Cipher Connect',
    items: [
      {
        title: 'Cipher Connect Configuration',
        permission: 'cipher_connect_program_management',
        to: 'CipherConnectConfiguration',
      },
    ],
  },
]
